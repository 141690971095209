import { Dialog } from '@axieinfinity/matcha'
import { DevicesFillIcon } from '@axieinfinity/matcha-icons'
import { format } from 'date-fns'
import type { FC } from 'react'

import type { TSession } from '~/apis/security'
import { ActivityCard } from '~/components/ActivityCard'
import {
  NiceModal,
  type NiceModalProps,
  useModal,
} from '~/components/NiceModal'

export type TActivityModalProps = {
  data: TSession
}

export const ActivityModal: FC<TActivityModalProps & NiceModalProps> =
  NiceModal.create(props => {
    const { data } = props
    const { visible, hide, remove } = useModal()

    return (
      <Dialog
        className="[&_.dango-dialog-content]:bg-surface-color-default [&_.dango-dialog-content]:border [&_.dango-dialog-content]:border-border-color-default [&_.dango-dialog-body]:p-0 [&_.dango-dialog-content]:!p-0 p-16 !w-[390px] sm:!w-[640px]"
        visible={visible}
        onClose={hide}
        afterClose={remove}
        destroyOnClose
        size="small"
        {...props}
      >
        <div className="flex flex-col gap-24 p-32">
          <div>
            <div className="text-h4 mb-4">
              {`Sign-in on ${data.device_info.name}`}
            </div>
            <div className="text-body-s text-text-color-subdued">
              {format(
                new Date((data?.last_updated || 0) * 1000),
                'MMM dd YYY, hh:MM a',
              )}
            </div>
          </div>
          <div>
            <div className="text-label-m mb-12 text-text-color-subdued">
              APPLICATION
            </div>
            <ActivityCard
              className="border-none !p-0 hover:bg-transparent"
              leftNode={
                <img
                  className="rounded-16"
                  width={48}
                  height={48}
                  src={data.request_info.app_info.icon_url}
                  alt="logo-app"
                />
              }
              title={data.request_info.app_info.name}
              description={`${format(
                new Date(data.last_updated * 1000),
                'MMM dd YYY',
              )}`}
            />
          </div>
          <div>
            <div className="text-label-m mb-12 text-text-color-subdued">
              DEVICE
            </div>
            <ActivityCard
              className="border-none !p-0 hover:bg-transparent"
              leftNode={
                <div className="rounded-circle bg-black-opacity-70 w-[48px] h-[48px] flex justify-center items-center">
                  <DevicesFillIcon size={24} />
                </div>
              }
              title={data.device_info.device_type}
              description={`${data.device_info.os} · ${data.device_info.name}`}
            />
          </div>
        </div>
      </Dialog>
    )
  })
