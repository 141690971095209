import clsx from 'clsx'

export type TCardProps = {
  className?: string
  title: React.ReactNode
  description?: React.ReactNode
  leftNode?: React.ReactNode
  rightNode?: React.ReactNode
  onClick?: () => void
}
export const ActivityCard: React.FC<TCardProps> = props => {
  const {
    title,
    description,
    leftNode,
    rightNode,
    onClick = () => {},
    className = '',
  } = props
  return (
    <div
      onClick={onClick}
      className={clsx(
        'cursor-pointer hover:bg-black-opacity-70 flex items-center justify-between py-20 px-8 border-b border-border-color-default-subdued',
        className,
      )}
    >
      <div className="flex items-center gap-16">
        {leftNode && <>{leftNode}</>}
        <div>
          <div className="text-body-m-strong mb-4">{title}</div>
          <div className="text-body-s text-text-color-subdued">
            {description}
          </div>
        </div>
      </div>
      {rightNode && <>{rightNode}</>}
    </div>
  )
}
