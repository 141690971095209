import {
  PUBLIC_AUTH_SERVER_URL,
  PUBLIC_CLIENT_ID,
  PUBLIC_CLIENT_URL,
  PUBLIC_RONIN_CONFIG,
} from '@account-service/core/envs'

import { createUUID } from '~/utils/helper'

export const AUTH_SERVER = PUBLIC_AUTH_SERVER_URL
export const RONIN_CONFIG = PUBLIC_RONIN_CONFIG as string

export const SENTRY_DSN = {
  production:
    'https://874ddeaefb284e9a8a17ab33eb0c15db@o998185.ingest.sentry.io/4504597043478528',
  development:
    'https://bb475829fc724d1283184d9dfc0c5693@o998185.ingest.sentry.io/4504399830712320',
  staging:
    'https://bb475829fc724d1283184d9dfc0c5693@o998185.ingest.sentry.io/4504399830712320',
}[RONIN_CONFIG]

export const SSO_AUTH_LOGIN_OBJ = {
  response_type: 'code',
  request_type: 'login',
  ux_mode: 'redirect',
  state: createUUID(),
  scope: 'openid offline_access',
  client_id: PUBLIC_CLIENT_ID,
  redirect_uri: `${PUBLIC_CLIENT_URL}/oauth2/callback`,
  remember: 'true',
}

export const CAPTCHA_REGISTER_ENDPOINT =
  new Map([
    ['production', 'https://captcha.axieinfinity.com/api/geetest/register'],
    ['development', 'https://captcha.skymavis.one/api/geetest/register'],
    ['staging', 'https://captcha.skymavis.one/api/geetest/register'],
  ]).get(RONIN_CONFIG) || 'https://captcha.skymavis.one/api/geetest/register'

export const SSO_SERVER =
  new Map([
    ['production', 'https://athena.skymavis.com/'],
    ['development', 'https://athena.skymavis.one/'],
    ['staging', 'https://athena.skymavis.one/'],
  ]).get(RONIN_CONFIG) || 'https://athena.skymavis.one/'

export const INTERVAL_CHECK_EMAIL_VERIFIED = 5000
export const HEIGHT_POPUP_WINDOW = 780

export const SSO_STATE_KEY = 'dashboard:state'

export const ACCOUNT_DASHBOARD_CLIENT_URL =
  new Map([
    ['production', 'https://accounts.skymavis.com'],
    ['development', 'https://accounts.skymavis.one'],
    ['staging', 'https://accounts-staging.skymavis.one'],
  ]).get(RONIN_CONFIG) || 'https://accounts.skymavis.com'

export const LOCKBOX_URL =
  new Map([
    ['production', 'https://lockbox.skymavis.com'],
    ['development', 'https://project-x.skymavis.one'],
    ['staging', 'https://project-x.skymavis.one'],
  ]).get(RONIN_CONFIG) || 'https://project-x.skymavis.one'

export const CLIENT_META = {
  name: 'Sky Mavis Account',
  description: 'Connect to Ronin Wallet from Sky Mavis Account',
  icon: 'https://cdn.skymavis.com/skymavis-home/public/favicon.ico',
  url: ACCOUNT_DASHBOARD_CLIENT_URL,
}

export const ONE_SIGNAL_IDS = {
  AXIE: '2a59b422-244f-47a7-aeaf-bcc1d1716b59',
  MAVIS: 'ec24513b-416c-48f5-ae58-4189ebabac19',
  RONIN: 'ea19fef5-90d1-459f-a72c-011eb5df6ba1',
}

export const walletConnectProjectId = 'd2ef97836db7eb390bcb2c1e9847ecdc'
export const defaultChainId = 2020
