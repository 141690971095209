import { genTimestamp, randomUUID } from '@account-service/core'
import {
  PUBLIC_TRACKING_API_KEY,
  PUBLIC_TRACKING_API_URL,
} from '@account-service/core/envs'
import axios from 'axios'

import { getSessionId, getUserId } from '~/core/cookie'

const TRACKING_URL = PUBLIC_TRACKING_API_URL
const API_KEY = btoa(`${PUBLIC_TRACKING_API_KEY}:`)

export enum EventName {
  // User starts sign in
  SIGN_IN_START = 'Sign in start',
  // User create wallet success
  CREATE_WALLET = 'Create wallet success',
  // User link wallet success
  LINK_WALLET = 'Link wallet success',
  // User unlink wallet success
  UNLINK_WALLET = 'Unlink wallet success',
  // User set up 2fa success
  SETUP_2FA = 'Set up 2fa success',
  // User set up password success
  SETUP_PASSWORD = 'Set up password success',
  // User set up email success
  SETUP_EMAIL = 'Set up email success',
  // User connect facebook
  CONNECT_FACEBOOK = 'Connect facebook',
  // User connect apple
  CONNECT_APPLE = 'Connect apple',
  // User connect google
  CONNECT_GOOGLE = 'Connect google',
  // User disconnect facebook
  DISCONNECT_FACEBOOK = 'Disconnect facebook',
  // User disconnect apple
  DISCONNECT_APPLE = 'Disconnect apple',
  // User disconnect google
  DISCONNECT_GOOGLE = 'Disconnect google',
  // Invalid
  INVALID = 'Invalid',
  // Screen
  DASHBOARD = 'Dashboard',
  SECURITY = 'Security',
}

export interface TDictionary<TValue> {
  [id: string]: TValue
}

export interface TBaseEventData {
  event: EventName | string
  ref?: EventName | string
  uuid?: string
  timestamp?: string
  session_id?: string
  offset?: number
  user_id?: string
}

export interface TIdentifyEventData extends TBaseEventData {
  build_version?: string
  device_name?: string
  device_id?: string
  platform_name?: string
  platform_version?: string
  internet_type?: string
  user_properties?: TDictionary<any>
}

export interface TTrackEventData extends TBaseEventData {
  action?: string
  action_properties?: TDictionary<any>
}

export interface TScreenEventData extends TBaseEventData {
  screen?: string
  screen_properties?: TDictionary<any>
}

export type TEventType = 'identify' | 'track' | 'screen'

export interface TEvent {
  type: TEventType
  data: TIdentifyEventData | TTrackEventData | TScreenEventData
}

export const sendEvent = async (event: TEvent) => {
  try {
    const eventWithMetadata: TEvent = {
      ...event,
      data: {
        ...event.data,
        uuid: randomUUID(),
        session_id: getSessionId(),
        user_id: getUserId(),
        timestamp: genTimestamp(),
      },
    }

    return await axios.post(
      TRACKING_URL,
      {
        events: [eventWithMetadata],
      },
      {
        headers: {
          authorization: `Basic ${API_KEY}`,
        },
      },
    )
  } catch (error) {
    console.debug(error)
    return undefined
  }
}
