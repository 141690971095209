import { PUBLIC_AUTH_SERVER_URL } from '@account-service/core/envs'
import axios from 'axios'

import { getAccessToken, getSSOToken } from '~/core/cookie'

import type { AuthenticationResponse } from './types'

export const logoutAPI = async () => {
  const { accessToken } = getAccessToken()
  const ssoToken = getSSOToken()

  return axios.post<AuthenticationResponse>(
    `${PUBLIC_AUTH_SERVER_URL}v2/private/auth/logout`,
    {
      access_token: ssoToken.access_token,
      refresh_token: ssoToken.refresh_token,
    },
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    },
  )
}

export const loginOAuthAPI = async (data: {
  code: string
  code_verifier: string
  redirect_url: string
  exchange_code_only?: boolean
}) => {
  const exData = data.exchange_code_only ? { exchange_code_only: true } : {}

  return axios.post<AuthenticationResponse>(
    `${PUBLIC_AUTH_SERVER_URL}v2/public/oauth2/login`,
    {
      ...data,
      ...exData,
    },
  )
}

export const refreshTokenAPI = async (refreshToken: string) => {
  const ssoToken = getSSOToken()
  return axios.post<AuthenticationResponse>(
    `${PUBLIC_AUTH_SERVER_URL}v2/public/auth/token/refresh`,
    {
      refreshToken,
      sso_refresh_token: ssoToken.refresh_token,
    },
  )
}
