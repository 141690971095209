import type { AxieRotationCaptchaData } from '@account-service/core'

import { request } from './request'
import type { DefaultWallet, Settings, User, ValidateData } from './types'

type TUserProfileParams = {
  email?: string
  emailOptIn?: boolean
  name?: string
  phone?: string
  birthday?: number
  addresses?: string
  locale?: string
}

type TChangePasswordParams = {
  oldPassword: string
  newPassword: string
  isLogoutOtherSessions: boolean
}

type TResetPasswordParams = {
  token: string
  password: string
  is_logout_other_sessions: boolean
}

export type TItemMethod = {
  authenticator_type: string
  method: string
  active: string
}

export const getProfileAPI = async () => {
  return request<User>({ url: 'v2/private/profiles/me' })
}

export const getProfileV2API = async () => {
  return request<Record<string, unknown>>({
    url: 'v2/private/users/me/profiles',
  })
}

export const updateProfileAPI = async (data: TUserProfileParams) => {
  return request<User>({ url: 'v2/private/profiles/me', method: 'PATCH', data })
}

export const updateProfileV2API = async (wallet: DefaultWallet) => {
  return request({
    url: 'v2/private/users/me/profiles',
    method: 'POST',
    data: {
      profile: {
        'wallet.default': wallet,
      },
    },
  })
}

export const getSettingAPI = async () => {
  return request<Settings>({ url: 'v2/private/users/me/settings' })
}

export const requestChangeEmailAPI = async (
  mfa_token: string,
  captchaData: AxieRotationCaptchaData,
  email: string,
) => {
  return request({
    url: 'v2/private/users/me/request-change-email',
    method: 'POST',
    headers: {
      'x-captcha': captchaData.token,
    },
    data: {
      email,
      mfa_token,
    },
  })
}

export const submitChangeEmailAPI = async (
  email: string,
  verify_code: string,
  mfa_token?: string,
) => {
  return request({
    url: 'v2/private/users/me/change-email',
    method: 'POST',
    data: {
      email,
      verify_code,
      mfa_token,
    },
  })
}

// Legacy API

export const changePasswordAPI = async (data: TChangePasswordParams) => {
  return request({
    url: 'v2/private/auth/password/change',
    method: 'POST',
    data,
  })
}

export const resetPasswordAPI = async (data: TResetPasswordParams) => {
  return request({
    method: 'POST',
    url: 'v2/public/auth/reset-password',
    data,
  })
}

export const setupEmailMagicLinkAPI = async (
  email: string,
  captcha: ValidateData,
) => {
  return request<{
    success: boolean
    message: string
    code: string
    check_code: string
  }>({
    url: 'v2/private/users/me/request-setup-email-magic-link',
    headers: {
      'x-captcha': JSON.stringify(captcha),
    },
    method: 'POST',
    data: {
      email,
    },
  })
}

export const requestSetupEmailAPI = async (
  email: string,
  captcha: ValidateData,
) => {
  return request<{ success: boolean; message: string; code: string }>({
    url: 'v2/private/users/me/request-setup-email',
    headers: {
      'x-captcha': JSON.stringify(captcha),
    },
    method: 'POST',
    data: {
      email,
      password: '',
    },
  })
}

export const setupEmailAPI = async (email: string, verify_code: string) => {
  return request({
    url: 'v2/private/users/me/setup-email',
    method: 'POST',
    data: {
      email,
      password: '',
      verify_code,
    },
  })
}

export const setupPasswordAPI = async (password: string) => {
  return request({
    url: 'v2/private/auth/password/setup',
    method: 'POST',
    data: {
      password,
      confirm_password: password,
      is_logout_other_sessions: false,
    },
  })
}

export const getMFAMethodsAPI = async () => {
  return request<TItemMethod[]>({
    url: 'v2/private/users/me/mfa/associates',
  })
}

export const removeMFAMethodAPI = async (
  method: string,
  authenticator_type: string,
  mfa_token?: string,
) => {
  return request({
    url: 'v2/private/auth/mfa/associates',
    method: 'DELETE',
    data: {
      method,
      authenticator_type,
      mfa_token,
    },
  })
}
