import 'styles/index.scss'
import '../configs/modal'

import { AccountServiceProvider, CaptchaProvider } from '@account-service/core'
import {
  PUBLIC_CAPTCHA_API_KEY,
  PUBLIC_CAPTCHA_API_URL,
  PUBLIC_FB_APPID,
  PUBLIC_GOOGLE_CLIENTID,
} from '@account-service/core/envs'
import { ConnectorProvider } from '@axieinfinity/connect'
import { ErrorBoundary } from '@sentry/nextjs'
import { CAPTCHA_REGISTER_ENDPOINT, SSO_SERVER } from 'constants/index'
import { AuthProvider } from 'contexts/auth'
import type { AppProps } from 'next/app'
import { Work_Sans } from 'next/font/google'
import Head from 'next/head'
import type { NextPageWithLayout } from 'types/next'

import Error from '~/components/Error'
import { NiceModal } from '~/components/NiceModal'
import { ToastProvider } from '~/contexts/toast'

const workSans = Work_Sans({
  subsets: ['latin'],
  variable: '--font-sans',
})

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App: React.FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  return (
    <>
      {/* @ts-ignore */}
      <style jsx global>{`
        :root {
          --font-sans: ${workSans.style.fontFamily};
        }
      `}</style>
      <Head>
        <title>Sky Mavis Account</title>
        <meta name="author" content="skymavis.com" />
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, maximum-scale=1"
        />
        <meta
          name="description"
          content="The one and only account you need to sign in and explore hundred of dApps and Games in Sky Mavis and Ronin ecosystem."
        />
        <meta property="og:title" content="Sky Mavis Account" />
        <meta property="og:site_name" content="Sky Mavis Account" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/dashboard/preview.png" />

        <link rel="shortcut icon" href="/dashboard/favicon.ico" />
      </Head>

      <ErrorBoundary
        fallback={
          <Error
            title="Something went wrong"
            description="Please try again later"
          />
        }
      >
        <AccountServiceProvider
          configs={{
            facebookAppId: PUBLIC_FB_APPID,
            googleAppId: PUBLIC_GOOGLE_CLIENTID,
            ssoServer: SSO_SERVER,
            geetestServer: CAPTCHA_REGISTER_ENDPOINT,
            appleAppId: 'com.skymavis.accounts.service',
          }}
        >
          <CaptchaProvider
            configs={{
              captchaKey: PUBLIC_CAPTCHA_API_KEY,
              captchaUrl: PUBLIC_CAPTCHA_API_URL,
            }}
          >
            <ToastProvider>
              <AuthProvider>
                <NiceModal.Provider>
                  <ConnectorProvider>
                    <Component {...pageProps} />
                  </ConnectorProvider>
                </NiceModal.Provider>
              </AuthProvider>
            </ToastProvider>
          </CaptchaProvider>
        </AccountServiceProvider>
      </ErrorBoundary>
    </>
  )
}

export default App
