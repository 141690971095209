import clsx from 'clsx'

export type TWellProps = {
  disabled?: boolean
  children: React.ReactNode
  leftNode?: React.ReactNode
  rightNode?: React.ReactNode
}

export const Well: React.FC<TWellProps> = props => {
  const { leftNode, rightNode, children, disabled = false } = props

  return (
    <div className="rounded-2x bg-light-1 flex w-full justify-between p-16 lg:p-12">
      <div className="flex items-center">
        <div className="rounded-2x flex h-32 w-32 items-center justify-center bg-white">
          {leftNode}
        </div>
        <p
          className={clsx('text-normal leading-20 mb-0 ml-8 truncate', {
            'text-light-9': !disabled,
            'text-light-7': disabled,
          })}
        >
          {children}
        </p>
      </div>

      {rightNode}
    </div>
  )
}
