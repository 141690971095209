import { request } from './request'

export type TResponsePagination<T> = {
  total: number
  data: T[]
}

export type TAppInfo = {
  id: string
  name: string
  icon_url: string
  url: string
}

export type TRequestInfo = {
  client_id: string
  app_info: TAppInfo
  granted_scopes: string[]
  grant_types: string[]
}

export type TDeviceInfo = {
  name: string
  device_type: string
  ip: string
  os: string
  browser: string
  raw: string
}

export type TSession = {
  id: string
  request_info: TRequestInfo
  device_info: TDeviceInfo
  created_at: number
  expired_at: number
  last_updated: number
}

export const getRecentActivitiesAPI = async () => {
  return request<TResponsePagination<TSession>>({
    url: 'v2/private/users/me/sessions',
  })
}

export const getConnectedAppsAPI = async () => {
  return request<TResponsePagination<TAppInfo>>({
    url: 'v2/private/users/me/connected-apps',
  })
}

export const getSessionsByAppIdAPI = async (client_id: string) => {
  return request<TResponsePagination<TSession>>({
    url: `v2/private/users/me/connected-apps/sessions?client_id=${client_id}`,
  })
}
