import { Button, Input, Intent, Shape } from '@axieinfinity/matcha'
import type { FormEvent } from 'react'

type TStepInputEmailProps = {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onClose: () => void
}
export const StepInputEmail = (props: TStepInputEmailProps) => {
  const { onSubmit, onClose } = props
  return (
    <form
      className="flex flex-col items-center text-center p-32 gap-24"
      onSubmit={onSubmit}
    >
      <div>
        <h4 className="mb-8 text-h4">Change your email</h4>
        <div className="text-body-s text-text-color-subdued">
          Please enter your new email
        </div>
      </div>

      <div className="w-full">
        <Input
          shape={Shape.Default}
          name="email"
          placeholder="Enter your email"
        />
      </div>

      <div className="w-full">
        <Button
          fullWidth
          text="Submit"
          className="mb-16"
          intent={Intent.Primary}
          shape={Shape.Default}
        />

        <span
          className="cursor-pointer text-body-s-strong text-text-color-highlight transition-all hover:opacity-80"
          onClick={onClose}
        >
          Back
        </span>
      </div>
    </form>
  )
}
