import { useEffect, useRef, useState } from 'react'

export const useCountDown: (
  seconds: number,
  ms?: number,
) => {
  counter: number
  start: () => void
  pause: () => void
  reset: () => void
  restart: (newSeconds?: number) => void
} = (seconds: number, ms = 1000) => {
  const [counter, setCountDown] = useState(seconds)
  const [startCountDown, setStartCountDown] = useState(false)

  // Store the created interval
  const intervalId = useRef<ReturnType<typeof setInterval>>()
  const start: () => void = () => setStartCountDown(true)
  const pause: () => void = () => setStartCountDown(false)
  const reset: () => void = () => {
    clearInterval(intervalId.current)
    setStartCountDown(false)
    setCountDown(seconds)
  }
  const restart: (newSeconds?: number) => void = newSeconds => {
    clearInterval(intervalId.current)
    setStartCountDown(true)
    setCountDown(newSeconds ?? seconds)
  }

  useEffect(() => {
    setCountDown(seconds)
  }, [seconds])

  useEffect(() => {
    intervalId.current = setInterval(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      startCountDown && counter > 0 && setCountDown(counter => counter - 1)
    }, ms)

    // Clear interval when count to zero
    if (counter === 0) clearInterval(intervalId.current)

    // Clear interval when unmount
    return () => clearInterval(intervalId.current)
  }, [startCountDown, counter, ms])

  return { counter, start, pause, reset, restart }
}
