import type { ToastConfig } from '@axieinfinity/matcha'
import { useToast } from '@axieinfinity/matcha'
import type { ReactNode } from 'react'
import React, { createContext, useContext } from 'react'

const defaultState = {
  openToast: () => {},
}

export const ToastContext = createContext<{
  openToast: (config: ToastConfig) => void
}>(defaultState)

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { openToast, contextHolder } = useToast({ closable: true, maxCount: 1 })

  return (
    <ToastContext.Provider
      value={{
        openToast,
      }}
    >
      {children}
      {contextHolder}
    </ToastContext.Provider>
  )
}

export const useToastContext = () => useContext(ToastContext)
