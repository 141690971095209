import { ActivityModal } from '~/components/Modals/ActivityModal'
import { ChangeEmailModal } from '~/components/Modals/ChangeEmailModal'
import { ConnectedAppModal } from '~/components/Modals/ConnectedAppModal'
import { PasswordModal } from '~/components/Modals/PasswordModal'
import { NiceModal } from '~/components/NiceModal'
import { ModalKey } from '~/constants/modal'

NiceModal.register(ModalKey.CHANGE_EMAIL, ChangeEmailModal)
NiceModal.register(ModalKey.PASSWORD, PasswordModal)
NiceModal.register(ModalKey.ACTIVITY, ActivityModal)
NiceModal.register(ModalKey.CONNECTED_APP, ConnectedAppModal)
