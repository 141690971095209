import type { NiceModalHocProps as NiceModalProps } from '@ebay/nice-modal-react'
import NiceModalReact from '@ebay/nice-modal-react'
import omit from 'lodash.omit'

import { ModalKey } from '~/constants/modal'

export { useModal } from '@ebay/nice-modal-react'

declare function show<P extends any>(
  modal: string | React.FC<any>,
  args?: P,
): Promise<any>

const hideAll = () => {
  Object.values(ModalKey).forEach(modalKey => NiceModalReact.hide(modalKey))
}

export const NiceModal: Omit<typeof NiceModalReact, 'show'> & {
  show: typeof show
  hideAll: typeof hideAll
} = {
  hideAll,
  show: NiceModalReact.show as typeof show,
  ...omit(NiceModalReact, 'show'),
}

export { type NiceModalProps }
