import {
  Button,
  ButtonVariant,
  Dialog,
  Intent,
  Shape,
} from '@axieinfinity/matcha'
import type { FC } from 'react'

import {
  NiceModal,
  type NiceModalProps,
  useModal,
} from '~/components/NiceModal'
import { PasswordInput } from '~/components/PasswordInput'
import { ModalKey } from '~/constants/modal'
import { ModeAction } from '~/constants/mode'

import { usePassword } from './usePassword'

export type TPasswordModalProps = {
  mode: ModeAction
}

export const PasswordModal: FC<TPasswordModalProps & NiceModalProps> =
  NiceModal.create(props => {
    const { mode } = props
    const { visible, hide, remove } = useModal()

    const {
      isLoading,
      errors,
      passwordData,
      handleChangeInput,
      onSubmitPassword,
    } = usePassword(props)

    return (
      <Dialog
        className="[&_.dango-dialog-content]:bg-surface-color-default [&_.dango-dialog-content]:border [&_.dango-dialog-content]:border-border-color-default [&_.dango-dialog-body]:p-0 [&_.dango-dialog-close]:hidden [&_.dango-dialog-content]:!p-0 p-16 !w-[390px] sm:!w-[480px]"
        visible={visible}
        onClose={hide}
        afterClose={remove}
        destroyOnClose
        size="small"
        {...props}
      >
        <form
          className="flex flex-col items-center p-32"
          onSubmit={onSubmitPassword}
        >
          <h4 className="mb-12 text-h4 text-center">
            {mode === ModeAction.CHANGE ? 'Change' : 'Set up'} your password
          </h4>
          <div className="mb-24 text-body-s text-center text-text-color-subdued">
            Your password must contain a minimum of 8 characters & contain both
            alphabets and numbers.
          </div>

          <div className="mb-24 w-full flex flex-col gap-16">
            {mode === ModeAction.CHANGE && (
              <PasswordInput
                autoFocus
                value={passwordData.oldPassword}
                name="oldPassword"
                placeholder="Current password"
                onChange={handleChangeInput}
                shape={Shape.Default}
                intent={Intent.Default}
                error={errors.oldPassword}
              />
            )}
            <PasswordInput
              {...(mode === ModeAction.SETUP ? { autoFocus: true } : {})}
              value={passwordData.newPassword}
              name="newPassword"
              placeholder="New password"
              onChange={handleChangeInput}
              shape={Shape.Default}
              intent={Intent.Default}
              error={errors.newPassword}
            />

            <PasswordInput
              value={passwordData.confirmPassword}
              name="confirmPassword"
              placeholder="Confirm new password"
              onChange={handleChangeInput}
              shape={Shape.Default}
              intent={Intent.Default}
              error={errors.confirmPassword}
            />
          </div>

          <Button
            fullWidth
            className="mb-8"
            text="Submit"
            type="submit"
            shape={Shape.Default}
            intent={Intent.Primary}
            loading={isLoading}
          />

          <Button
            fullWidth
            text="Cancel"
            type="button"
            shape={Shape.Default}
            intent={Intent.Primary}
            variant={ButtonVariant.Plain}
            onClick={() => NiceModal.hide(ModalKey.PASSWORD)}
          />
        </form>
      </Dialog>
    )
  })
