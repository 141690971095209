import type { InputProps } from '@axieinfinity/matcha'
import { Input, Intent } from '@axieinfinity/matcha'
import {
  EyeClosedIcon,
  EyeIcon as EyeOpenIcon,
} from '@axieinfinity/matcha-icons'
import React, { memo, useCallback, useState } from 'react'

type Props = InputProps & {
  textSuffix?: string
  error?: string
  onClickTextSuffix?: () => void
}

export const PasswordInput: React.FC<Props> = props => {
  const {
    value = '',
    textSuffix = '',
    onClickTextSuffix = () => {},
    error = '',
  } = props
  const [isHidden, setIsHidden] = useState(true)

  const toggleHidden = useCallback(
    () => setIsHidden(prevHidden => !prevHidden),
    [],
  )

  const EyeIcon = memo(function EyeIcon() {
    return (
      <div className="cursor-pointer select-none" onClick={toggleHidden}>
        {isHidden ? (
          <EyeOpenIcon size={24} color="#8F9BB3" />
        ) : (
          <EyeClosedIcon size={24} color="#8F9BB3" />
        )}
      </div>
    )
  })

  return (
    <Input
      {...props}
      maxLength={255}
      type={isHidden ? 'password' : 'text'}
      autoComplete="password"
      value={value}
      intent={error ? Intent.Danger : props.intent}
      caption={error}
      suffixIcon={
        // eslint-disable-next-line no-nested-ternary
        value ? (
          <EyeIcon />
        ) : textSuffix ? (
          <span
            className="cursor-pointer text-body-s-strong text-text-color-highlight transition-all hover:opacity-80"
            onClick={onClickTextSuffix}
          >
            {textSuffix}
          </span>
        ) : undefined
      }
    />
  )
}
