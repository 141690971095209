// Map error_status to message
// https://skymavis.atlassian.net/wiki/spaces/SA/pages/73695233/Error+Code

export type TErrors = Record<string, string>

export const ERRORS = {
  ERR_EMAIL_REGISTERED: 'The email has been registered before',
  ERR_THIRD_PARTY_ACCOUNT_REGISTERED:
    'The third party account has been linked before',
  ERR_PASSWORD_NOT_MATCH_PATTERN:
    'Passwords must be at least 8 characters including a number and an alphabet',
  ERR_EMAIL_ADDRESS_INVALID: 'The email address is in an invalid format',
  ERR_OAUTH_SERVICE_NOT_SUPPORTED: 'Not supported login with this service',
  ERR_WRONG_EMAIL_OR_PASSWORD: 'Your email or password is wrong',
  ERR_EMAIL_NOT_REGISTERED: 'The email has not been registered',
  ERR_BASIC_AUTH_INVALID: 'Invalid basic auth',
  ERR_REFRESH_TOKEN_EXPIRED: 'Refresh token expired',
  ERR_REFRESH_TOKEN_INVALID: 'Refresh token invalid',
  ERR_OAUTH_TOKEN_INVALID: 'Disconnect wrong account',
  ERR_FACEBOOK_OAUTH_TOKEN_INVALID: 'Facebook token is invalid',
  ERR_FACEBOOK_OAUTH_APP_ID_INVALID: 'Facebook app id is invalid',
  ERR_APPLE_OAUTH_CLIENT_ID_INVALID: 'Apple client id is invalid',
  ERR_APPLE_OAUTH_TOKEN_INVALID: 'Apple token is invalid',
  ERR_ETHEREUM_OAUTH_TOKEN_INVALID: 'Ethereum token is invalid',
  ERR_ETHEREUM_SIGNATURE_LENGTH: 'Signature must be 65 bytes long',
  ERR_RONIN_OAUTH_TOKEN_INVALID: 'Ronin token is invalid',
  ERR_RONIN_ADDRESS_IS_BLOCKED_TO_LINK_OTHER_ACCOUNT:
    'Your ronin address is blocked to link other account',
  ERR_RONIN_ADDRESS_IS_BLOCKED_TO_LINK_OTHER_ACCOUNT_IN_24H:
    'Your ronin address is blocked to link other account in 24h',
  ERR_LINK_WITH_EXIST_PROFILE: 'You cannot link with exist profile',
  ERR_ACCOUNT_CONNECTED_WITH_OTHER_EMAIL:
    'Your account is connected with other email address',
  ERR_PASSWORD_RESET_WRONG_EMAIL_OR_TOKEN:
    'Password reset token or email wrong',
  ERR_PASSWORD_RESET_TOKEN_EXPIRED: 'Password reset token has expired',
  ERR_VERIFY_EMAIL_WRONG_EMAIL_OR_TOKEN: 'Verify email token or email wrong',
  ERR_VERIFY_EMAIL_WRONG_TOKEN: 'Verify email with wrong token',
  ERR_VERIFY_EMAIL_TOKEN_EXPIRED: 'Verify email token has expired',
  ERR_VERIFY_EMAIL_TOKEN_ALREADY_SENT: 'Verify email token has already sent',
  ERR_NON_USER_EMAIL_CHANGE_PASSWORD:
    'Only user registered with email is allowed to change password',
  ERR_CHANGE_PASSWORD_WRONG_OLD_PASSWORD: 'Wrong old password',
  ERR_NEW_PASSWORD_MUST_BE_DIFFERENT_FROM_OLD_PASSWORD:
    'Your new password must be different from your previous password',
  ERR_THIRD_PARTY_ACCOUNT_UNREGISTERED: `The third party account hasn't been linked before`,
  ERR_EMAIL_NOT_VERIFIED: 'Your email is not verified',
  ERR_GOOGLE_OAUTH_TOKEN_INVALID: 'Google id token is invalid',
  ERR_GOOGLE_OAUTH_CLIENT_ID_INVALID: 'Google app is unsupported',
  ERR_UNLINK_LAST_SOCIAL_ACCOUNT:
    'You cannot unlink the last third party account.',
  ERR_UNLINK_RONIN_ADDRESS_WITH_WEB3AUTH:
    'You cannot unlink wallet created with Sky Mavis account',
  ERR_ACCOUNT_INACTIVATED: 'Your account has been inactivated.',
  ERR_EMAIL_NOT_MATCH_WITH_GOOGLE_MAIL:
    'Your Google email is not match with current email.',
  ERR_EMAIL_IS_ALREADY_VERIFIED: 'Your email has been already verified.',
  ERR_RONIN_DOES_NOT_MATCH: `Your current account and Ronin wallet address doesn't match.`,
  ERR_CAPTCHA_IS_INVALID: 'The captcha is invalid.',
  ERR_LOCKED_BY_ATTEMPT_LOGIN:
    'You have been locked out due to too many failed attempt.',
  ERR_ATTEMPT_LOGIN: 'You are attempting to log in.',
  ERR_WRONG_2FA_PASSCODE: 'Your passcode is wrong',
  ERR_ACCESS_KEY_INVALID: 'Your access key is invalid',
  ERR_2FA_KEY_ALREADY_EXISTS: '2FA access key already exists',
  ERR_2FA_DISABLED: '2FA is disabled',
  ERR_2FA_ENABLED_BEFORE: '2FA is enabled before',
  ERR_2FA_INVALID_RECOVERY_CODE: 'Recovery code is wrong',
  ERR_2FA_REQUIRED: '2FA is required',
  ERR_2FA_USED_RECOVERY_CODE_BEFORE: 'Recovery code is used before',
  ERR_VALIDATE_SECRET_INVALID_BASE32: 'Decoding of secret as base32 failed.',
  ERR_VALIDATE_INPUT_INVALID_LENGTH: 'Input length unexpected',
  ERR_GENERATE_MISSING_ISSUER: 'Issuer must be set',
  ERR_GENERATE_MISSING_ACCOUNT_NAME: 'Account name must be set',
  ERR_USERID_NOT_FOUND: 'Your user_id is not found',
  ERR_USER_ALREADY_SETUP_EMAIL: 'Your email address has already been setup',
  ERR_UNABLE_TO_SETUP_BACKUP_CODE: 'You cannot setup backup code',
  ERR_LOGIN_SDK: 'You have cancelled login or did not fully authorize',
  ERR_OTP_INVALID: 'Incorrect OTP.',
  CAPTCHA_LOAD_FAILED:
    'Captcha load failed! Please turn off the Ad-Blocker and try again.',

  ERR_USER_EMAIL_NOT_SET: 'You must set up your email first',
  ERR_EMAIL_IN_DISPOSABLE_LIST: `Your email is invalid. It's on the disposable list`,
  ERR_INVALID_ARGUMENT: `You could not use the email alias with a sign '+'`,
} as TErrors

export const ERRORS_EMAIL = [
  'ERR_OTP_INVALID',
  'ERR_EMAIL_REGISTERED',
  'ERR_EMAIL_ADDRESS_INVALID',
  'ERR_WRONG_EMAIL_OR_PASSWORD',
  'ERR_EMAIL_NOT_REGISTERED',
  'ERR_ACCOUNT_CONNECTED_WITH_OTHER_EMAIL',
  'ERR_VERIFY_EMAIL_WRONG_EMAIL_OR_TOKEN',
  'ERR_VERIFY_EMAIL_WRONG_TOKEN',
  'ERR_VERIFY_EMAIL_TOKEN_EXPIRED',
  'ERR_EMAIL_NOT_VERIFIED',
  'ERR_EMAIL_NOT_MATCH_WITH_GOOGLE_MAIL',
  'ERR_EMAIL_IS_ALREADY_VERIFIED',
  'ERR_VERIFY_EMAIL_TOKEN_ALREADY_SENT',
  'ERR_NON_USER_EMAIL_CHANGE_PASSWORD',
]

// Errors API
