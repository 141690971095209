import { Background } from 'components'
import MavisLoading from 'components/MavisLoading'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const Page: NextPage = () => {
  const router = useRouter()
  useEffect(() => {
    router.push('/account')
  }, [router])

  return (
    <Background>
      <MavisLoading text="Redirecting" redirect="/" />
    </Background>
  )
}

export default Page
