import { Button, Intent, Shape } from '@axieinfinity/matcha'
import type { FormEventHandler } from 'react'

import { AuthCodeInput } from '~/components/AuthCodeInput'

import { ResendButton } from '../ResendButton'

type TStepVerifyEmailProps = {
  counter: number
  email: string
  isLoading?: boolean
  isResending?: boolean
  onChangeCode: (code: string) => void
  onSubmit: FormEventHandler<HTMLFormElement>
  handleResendEmail: () => void
  onClose: () => void
}
export const StepVerifyEmail = (props: TStepVerifyEmailProps) => {
  const {
    email,
    isLoading,
    isResending,
    counter,
    onSubmit,
    onClose,
    onChangeCode,
    handleResendEmail,
  } = props
  return (
    <form
      className="flex flex-col items-center text-center p-32 gap-24"
      onSubmit={onSubmit}
    >
      <div>
        <h4 className="mb-8 text-h4">Verify your email</h4>
        <div className="text-body-s text-text-color-subdued">
          Please enter the code we’ve sent to
        </div>
        <div className="w-full text-center truncate">{email}</div>
      </div>

      <AuthCodeInput autoFocus onChange={onChangeCode} />

      <ResendButton
        counter={counter}
        isResending={isResending}
        onResend={handleResendEmail}
      />

      <div className="w-full">
        <Button
          fullWidth
          text="Submit"
          className="mb-16"
          intent={Intent.Primary}
          shape={Shape.Default}
          loading={isLoading}
        />

        <span
          className="cursor-pointer text-body-s-strong text-text-color-highlight transition-all hover:opacity-80"
          onClick={onClose}
        >
          Back
        </span>
      </div>
    </form>
  )
}
