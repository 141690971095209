import { Button, Intent, Shape } from '@axieinfinity/matcha'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { FC, ReactNode } from 'react'
import React from 'react'

import image404 from '~/public/images/404.png'

import styles from './styles.module.scss'

export type Props = {
  title?: string | ReactNode
  description?: string | ReactNode
  moreInfo?: string | ReactNode
  action?: ReactNode
}

const Error: FC<Props> = ({ title, description, moreInfo, action }) => {
  const router = useRouter()
  return (
    <div className="flex justify-center items-center p-16">
      <div className={clsx('flex flex-col', styles.container)}>
        <img
          className={styles.image}
          width={187.46}
          height={158}
          src={image404}
          alt="404"
        />

        <div
          className={clsx(
            'border-1 flex flex-col items-center p-20 text-center sm:px-28 sm:pt-20 sm:pb-24',
            styles.box,
          )}
        >
          <div className={'mc-head-xs sm:mc-head-md mb-6 break-all sm:mb-12'}>
            {title}
          </div>
          <div className="mc-text-sm text-text-color-subdued sm:mc-text-md break-all mb-24">
            {description}
          </div>
          {action || (
            <Button
              shape={Shape.Default}
              intent={Intent.Primary}
              text="Back to home"
              onClick={() => router.replace('/')}
            />
          )}
        </div>

        {moreInfo && (
          <div className="mt-28 text-center text-sm">{moreInfo}</div>
        )}
      </div>
    </div>
  )
}
export default Error
