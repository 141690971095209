import { isMobile } from 'react-device-detect'
import { UAParser } from 'ua-parser-js'

export const isMobileDevice = () => isMobile

export const isFromIOS = () => {
  if (typeof window === 'undefined') {
    return false
  }

  const ua = new UAParser().getResult()

  return !!ua.os.name && ['iOS', 'Mac OS'].indexOf(ua.os.name) > -1
}
