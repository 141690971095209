import clsx from 'clsx'
import React from 'react'
import type { AuthCodeProps, AuthCodeRef } from 'react-auth-code-input'
import AuthCode from 'react-auth-code-input'

import styles from './styles.module.scss'

export type { AuthCodeRef }

export type AuthCodeInputProps = AuthCodeProps & {
  className?: string
  disabled?: boolean
  error?: string
  onChange: (code: string) => void
}

export const AuthCodeInput = React.forwardRef(
  (props: AuthCodeInputProps, ref: React.Ref<AuthCodeRef>) => {
    const {
      className = '',
      disabled = false,
      inputClassName = '',
      error,
      onChange = () => {},
    } = props

    return (
      <div>
        <AuthCode
          ref={ref}
          disabled={disabled}
          allowedCharacters="numeric"
          containerClassName={clsx(styles.container, className)}
          inputClassName={clsx(styles.input, inputClassName, {
            // @ts-ignore
            [styles.error]: !!error,
          })}
          onChange={onChange}
        />
        <div
          className={clsx('mc-text-sm ml-8 text-red-5', styles.caption, {
            // @ts-ignore
            [styles.show]: error,
            'mt-4 text-red-5': error,
          })}
        >
          {error}
        </div>
      </div>
    )
  },
)
