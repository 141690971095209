import type { ToastConfig } from '@axieinfinity/matcha'
import { Intent } from '@axieinfinity/matcha'
import {
  CheckCircleFillIcon,
  InfoFillIcon,
  WarningCircleFillIcon,
} from '@axieinfinity/matcha-icons'
import type { ReactNode } from 'react'
import type React from 'react'

import { useToastContext } from '~/contexts/toast'

type ToastOptions = Partial<Omit<ToastConfig, 'content'>>

const defaultOptions: ToastConfig = {
  title: '',
  content: 'An unexpected error occurred, please try again later',
  placement: 'top',
  duration: 3,
}

export const useWrappedToast = () => {
  const { openToast } = useToastContext()

  function showError(
    content?: React.ReactNode | string | unknown,
    options?: ToastOptions,
  ) {
    if (content instanceof Error) {
      openToast({
        ...defaultOptions,
        ...options,
        icon: WarningCircleFillIcon,
        content: content.message,
        intent: Intent.Danger,
      })
      return
    }

    if (typeof content === 'string' || typeof content === 'object') {
      openToast({
        ...defaultOptions,
        ...options,
        content: content as ReactNode,
        icon: WarningCircleFillIcon,
        intent: Intent.Danger,
      })
    }
  }

  function showSuccessMessage(
    content?: React.ReactNode | string | unknown,
    options?: ToastOptions,
  ) {
    if (typeof content === 'string' || typeof content === 'object') {
      openToast({
        ...defaultOptions,
        ...options,
        content: content as ReactNode,
        icon: CheckCircleFillIcon,
        intent: Intent.Success,
      })
    }
  }
  function showInfoMessage(
    content?: React.ReactNode | string | unknown,
    options?: ToastOptions,
  ) {
    if (typeof content === 'string' || typeof content === 'object') {
      openToast({
        ...defaultOptions,
        ...options,
        content: content as ReactNode,
        icon: InfoFillIcon,
        intent: Intent.Info,
      })
    }
  }

  return {
    defaultOptions,
    showError,
    showSuccessMessage,
    showInfoMessage,
  }
}
