import { Dialog, Intent, Loader } from '@axieinfinity/matcha'
import { DevicesFillIcon } from '@axieinfinity/matcha-icons'
import { format } from 'date-fns'
import type { FC } from 'react'
import { useEffect, useMemo, useState } from 'react'

import type { ErrorResponse } from '~/apis'
import {
  getSessionsByAppIdAPI,
  type TAppInfo,
  type TResponsePagination,
  type TSession,
} from '~/apis/security'
import { ActivityCard } from '~/components/ActivityCard'
import {
  NiceModal,
  type NiceModalProps,
  useModal,
} from '~/components/NiceModal'
import { useWrappedToast } from '~/hooks/useWrappedToast'

export type TConnectedAppModalProps = {
  data: TAppInfo
}

export const ConnectedAppModal: FC<TConnectedAppModalProps & NiceModalProps> =
  NiceModal.create(props => {
    const { data } = props
    const { visible, hide, remove } = useModal()
    const { showError } = useWrappedToast()
    const [sessions, setSessions] = useState<TResponsePagination<TSession>>({
      data: [],
      total: 0,
    })

    const [isLoading, setIsLoading] = useState(false)

    const handleGetSessionsByApp = async (appId: string) => {
      setIsLoading(true)
      try {
        const rs = await getSessionsByAppIdAPI(appId)
        setSessions({
          data: rs.data || [],
          total: rs.total,
        })
      } catch (error) {
        const { error_message } = error as ErrorResponse
        showError(error_message)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      if (data.id) handleGetSessionsByApp(data.id)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.id])

    const arrSessions = useMemo(() => sessions.data, [sessions.data])

    return (
      <Dialog
        className="[&_.dango-dialog-content]:bg-surface-color-default [&_.dango-dialog-content]:border [&_.dango-dialog-content]:border-border-color-default [&_.dango-dialog-body]:p-0 [&_.dango-dialog-content]:!p-0 p-16 !w-[390px] sm:!w-[640px]"
        visible={visible}
        onClose={hide}
        afterClose={remove}
        destroyOnClose
        size="small"
        {...props}
      >
        <div className="flex flex-col gap-24 p-32">
          <div>
            <div className="text-h4 mb-4">{data.name}</div>
            <div className="text-body-s text-text-color-subdued">
              {data.url}
            </div>
          </div>
          <div className="overflow-auto h-[360px]">
            {(() => {
              if (isLoading)
                return (
                  <div className="flex justify-center items-center h-full">
                    <Loader.Circle intent={Intent.Info} />
                  </div>
                )
              if (arrSessions.length > 0)
                return (
                  <>
                    <div className="mb-24">
                      <div className="text-label-m mb-12 text-text-color-subdued uppercase">
                        Last sign in
                      </div>
                      <ActivityCard
                        className="border-none !p-0 hover:bg-transparent"
                        leftNode={
                          <div className="rounded-circle bg-black-opacity-70 w-[48px] h-[48px] flex justify-center items-center">
                            <DevicesFillIcon size={24} />
                          </div>
                        }
                        title={
                          arrSessions[arrSessions.length - 1]?.device_info
                            .device_type
                        }
                        description={`${format(
                          new Date(
                            (arrSessions[arrSessions.length - 1]
                              ?.last_updated || 0) * 1000,
                          ),
                          'MMM dd YYY, hh:MM a',
                        )} · ${
                          arrSessions[arrSessions.length - 1]?.device_info.os
                        } · ${
                          arrSessions[arrSessions.length - 1]?.device_info.name
                        }`}
                      />
                    </div>
                    <div>
                      <div className="text-label-m mb-12 text-text-color-subdued uppercase">
                        Active connections by device
                      </div>
                      <div className="flex flex-col gap-12">
                        {arrSessions.map(item => (
                          <ActivityCard
                            key={item.id}
                            className="border-none !p-0 hover:bg-transparent"
                            leftNode={
                              <div className="rounded-circle bg-black-opacity-70 w-[48px] h-[48px] flex justify-center items-center">
                                <DevicesFillIcon size={24} />
                              </div>
                            }
                            title={item.device_info.device_type}
                            description={`${format(
                              new Date(item?.last_updated * 1000),
                              'MMM dd YYY, hh:MM a',
                            )} · ${item.device_info.os} · ${
                              item.device_info.name
                            }`}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                )
              return undefined
            })()}
          </div>
        </div>
      </Dialog>
    )
  })
