import { SHA256 } from 'crypto-js'
import type { ChangeEvent } from 'react'
import { useCallback, useState } from 'react'

import type { ErrorResponse } from '~/apis'
import { changePasswordAPI, setupPasswordAPI } from '~/apis'
import { EventName, sendEvent } from '~/apis/tracking'
import { NiceModal } from '~/components/NiceModal'
import { ERRORS } from '~/constants'
import { ModalKey } from '~/constants/modal'
import { ModeAction } from '~/constants/mode'
import { useSentry } from '~/core/sentry'
import { useAuthContext } from '~/hooks/useAuthContext'
import { useWrappedToast } from '~/hooks/useWrappedToast'
import { hashPassword } from '~/utils'
import { validatePassword } from '~/utils/validator'

import type { TPasswordModalProps } from '.'

type TFormSetupPassword = {
  oldPassword?: string
  newPassword: string
  confirmPassword: string
}
const initData: TFormSetupPassword = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const MESSAGE_ERROR_CONFIRM_PASSWORD_MATCH =
  'Confirm password and new password does not match'
const MESSAGE_ERROR_OLD_PASSWORD_EMPTY = 'Current password is required'
const MESSAGE_ERROR_NEW_PASSWORD_DIFFERENCE =
  'New password must be different from current password'

export const usePassword = (props: TPasswordModalProps) => {
  const { mode } = props
  const { user, getSettings } = useAuthContext()
  const { showSuccessMessage } = useWrappedToast()
  const { captureException } = useSentry()

  const [isLoading, setIsLoading] = useState(false)

  const [errors, setErrors] = useState(initData)
  const [passwordData, setPasswordData] = useState(initData)

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (isLoading) return

      const { name, value } = event.target

      setPasswordData(prev => ({
        ...prev,
        [name]: value,
      }))

      setErrors(prev => ({
        ...prev,
        [name]: '',
      }))
    },
    [isLoading],
  )

  const validateForm = useCallback(
    (data: TFormSetupPassword) => {
      const { oldPassword, newPassword, confirmPassword } = data

      let errorOldPassword = ''
      let errNewPassword = validatePassword(newPassword)
      let errConfirmPassword = ''

      if (mode === ModeAction.CHANGE) {
        errorOldPassword = !oldPassword
          ? MESSAGE_ERROR_OLD_PASSWORD_EMPTY
          : errorOldPassword

        errNewPassword =
          newPassword === oldPassword
            ? MESSAGE_ERROR_NEW_PASSWORD_DIFFERENCE
            : errNewPassword
      }

      errConfirmPassword =
        newPassword !== confirmPassword
          ? MESSAGE_ERROR_CONFIRM_PASSWORD_MATCH
          : errConfirmPassword

      return {
        isValid: !errNewPassword && !errConfirmPassword && !errorOldPassword,
        errors: {
          oldPassword: errorOldPassword,
          newPassword: errNewPassword,
          confirmPassword: errConfirmPassword,
        },
      }
    },
    [mode],
  )

  const handleSubmitPassword = useCallback(
    async (data: TFormSetupPassword) => {
      if (isLoading) return

      setIsLoading(true)
      try {
        const { newPassword, oldPassword = '' } = data
        if (mode === ModeAction.SETUP) {
          const rqPassword = hashPassword(newPassword)

          await setupPasswordAPI(rqPassword)

          showSuccessMessage('Your password has been set.')

          sendEvent({
            type: 'track',
            data: {
              event: EventName.SETUP_PASSWORD,
              action: EventName.SETUP_PASSWORD,
              ref: EventName.SETUP_PASSWORD,
            },
          })

          getSettings()
        } else {
          await changePasswordAPI({
            oldPassword: SHA256(oldPassword).toString(),
            newPassword: SHA256(newPassword).toString(),
            isLogoutOtherSessions: true,
          })
          showSuccessMessage('Your password has been changed.')
        }
        NiceModal.hide(ModalKey.PASSWORD)
      } catch (error) {
        const { error_status } = error as ErrorResponse
        if (error_status === 'ERR_CHANGE_PASSWORD_WRONG_OLD_PASSWORD') {
          setErrors(pre => ({ ...pre, oldPassword: ERRORS[error_status] }))
          return
        }

        captureException(error)
      } finally {
        setIsLoading(false)
      }
    },
    [captureException, getSettings, isLoading, mode, showSuccessMessage],
  )

  const onSubmitPassword = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()

      const { isValid, errors } = validateForm(passwordData)

      if (!isValid) {
        setErrors(errors)
        return
      }

      handleSubmitPassword(passwordData)
    },
    [handleSubmitPassword, passwordData, validateForm],
  )

  return {
    user,
    errors,
    isLoading,
    passwordData,
    handleChangeInput,
    onSubmitPassword,
  }
}
