/* eslint-disable no-bitwise */
import CryptoJS, { SHA256 } from 'crypto-js'
import set from 'lodash.set'

export function unflattenObject<T extends Record<string, unknown>>(
  obj: Record<string, unknown>,
): T {
  return Object.keys(obj).reduce(
    (result, key) => set(result, key, obj[key]),
    {} as T,
  )
}

export const toRoninAddress = (address: string): string => {
  return address.replace('0x', 'ronin:').toLowerCase()
}

export function createUUID(): string {
  let dt = new Date().getTime()
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    },
  )
  return uuid
}

export const openMailBoxPage = () => {
  window.open('https://mail.google.com/mail/u/0/#inbox', '_blank')
}

const pad = (n: number) => (n < 10 ? `0${n}` : n)

// t: seconds -> '6 days and 23:00:00'

export const toStringTime = (t: number) => {
  const sDay = 24 * 60 * 60
  const sHour = 60 * 60
  const sMinute = 60
  let days = Math.floor(t / sDay)
  let hours = Math.floor((t - days * sDay) / sHour)
  let minutes = Math.floor((t - days * sDay - hours * sHour) / sMinute)
  let seconds = Math.round(t - days * sDay - hours * sHour - minutes * sMinute)

  if (seconds === 60) {
    minutes++
    seconds = 0
  }

  if (minutes === 60) {
    hours++
    minutes = 0
  }
  if (hours === 24) {
    days++
    hours = 0
  }

  let str = ''

  if (days > 0) {
    str += `${days} days and `
  }

  str += [pad(hours), pad(minutes), pad(seconds)].join(':')

  return str
}

// time: 2023-03-23T18:51:37Z

export const diffTimeToNow = (time: string) => {
  if (!time) return 0

  const diff = new Date(time).getTime() - new Date().getTime()
  return diff >= 0 ? Math.floor(diff / 1000) : 0
}

export const getOffsetElement = (id?: string, offset = 32) => {
  const element = document.getElementById(id ?? '')
  return element ? element.offsetTop - offset : 0
}

export const scrollToElement = (id: string) => {
  const offsetTop = getOffsetElement(id)

  const background = document.getElementById('background')

  background?.scrollTo({
    top: offsetTop,
    behavior: 'smooth',
  })
}

export const generateRandomString = (length = 50) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  let result = ''

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return result
}

export const base64UrlEncode = (input: string) => {
  return input.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const generateCodeChallenge = (codeVerifier: string) => {
  const codeChallenge = SHA256(codeVerifier).toString(CryptoJS.enc.Base64)
  return base64UrlEncode(codeChallenge)
}

export const shortString = ({ str = '', left = 5, right = 5 }) => {
  return str.length > left + right + 5
    ? `${str.slice(0, left)}...${str.slice(str.length - right, str.length)}`
    : str
}
