import { PUBLIC_AUTH_SERVER_URL } from '@account-service/core/envs'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import mem from 'mem'

import {
  getAccessToken,
  getRefreshToken,
  removeAuthCookies,
  setAuthCookies,
} from '~/core/cookie'

import { refreshTokenAPI } from './auth'
import type { ErrorResponse } from './types'

export class EmptyPayloadError extends Error {
  name = 'EmptyPayload.'

  message = 'Empty payload.'
}

export class EmptyTokenError extends Error {
  name = 'EmptyToken.'

  message = 'Empty Token.'
}

export const axiosInstance = axios.create({
  baseURL: PUBLIC_AUTH_SERVER_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

const redirectToHome = () => {
  removeAuthCookies()
  window.location.replace('/')
}

export const refreshTokenFn = async () => {
  try {
    const refreshToken = getRefreshToken()

    const { data } = await refreshTokenAPI(refreshToken)

    if (data) {
      setAuthCookies(data)
      return data
    }
    redirectToHome()
    return undefined
  } catch (error) {
    redirectToHome()
    return undefined
  }
}

const maxAge = 10000

const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
})

axiosInstance.interceptors.request.use(
  async config => {
    const { accessToken } = getAccessToken()

    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`
    }

    return config
  },
  error => Promise.reject(error),
)

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const config = error?.config
    const { data, status } = error?.response

    if (status === 401 && data.error_status === 'ERR_BEARER_INVALID_TOKEN') {
      const result = await memoizedRefreshToken()

      if (result?.accessToken) return axiosInstance.request(config)
    }

    return Promise.reject(error)
  },
)

export const request = async <T>(options: AxiosRequestConfig): Promise<T> => {
  try {
    const refreshToken = getRefreshToken()
    const { accessToken } = getAccessToken()

    if (!accessToken && !refreshToken) {
      window.location.replace('/')
      return await Promise.reject(new EmptyTokenError())
    }

    const response = await axiosInstance(options)
    if (!response.data) return await Promise.reject(new EmptyPayloadError())
    return response.data
  } catch (error) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(
      (error as AxiosError)?.response?.data as ErrorResponse,
    )
  }
}
