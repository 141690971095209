import { request } from './request'

type TDisable2FAParams = { passcode: string } | { recoveryCode: string }

export type TQRTwoFactorResponse = {
  mashedSecret: string
  otpQrURI: string
}

export type TEnable2FAResponse = {
  recoveryCodes: string[]
}

export const disableTwoFactorAPI = async (data: TDisable2FAParams) => {
  return request({
    url: 'v2/private/users/me/settings/2fa',
    method: 'DELETE',
    data,
  })
}

export const getQRTwoFactorAPI = async () => {
  return request<TQRTwoFactorResponse>({
    url: 'v2/private/users/me/settings/2fa',
    method: 'POST',
  })
}

export const enableTwoFactorAPI = async (passcode: string) => {
  return request<TEnable2FAResponse>({
    url: 'v2/private/users/me/settings/2fa/enable',
    method: 'POST',
    data: {
      passcode,
    },
  })
}
