import { randomUUID } from '@account-service/core'

import type { AuthenticationResponse, SSOToken } from '~/apis/types'
import { cookieManager } from '~/utils'

type Options = {
  path?: string
  expires?: Date
  maxAge?: number
  domain?: string
  secure?: boolean
  sameSite?: 'strict' | 'lax' | 'none'
}

export const defaultOptions: Options = {
  path: '/',
  secure: process.env.NODE_ENV === 'production',
}

export enum ObservableCookies {
  AccessToken = 'accessToken',
  ExpiresAt = 'expiresAt',
  RefreshToken = 'refreshToken',
  SSOToken = 'SSOToken',
  CodeVerifier = 'codeVerifier',
  UserId = 'userId',
  SessionId = 'sessionId',
  TokenId = 'tokenId',
}

export type TAccessToken = {
  accessToken: string
  expireAt: string
}

const EXPIRES_DAYS = 30

export const getSessionId = () =>
  cookieManager.getCookie(ObservableCookies.SessionId) ?? randomUUID()

export const setSessionId = () => {
  const expires = new Date()
  expires.setDate(expires.getDate() + EXPIRES_DAYS)

  const sessionId = getSessionId()

  cookieManager.setCookies(ObservableCookies.SessionId, sessionId, {
    ...defaultOptions,
    expires,
  })
}

export const setAuthCookies = (data: AuthenticationResponse) => {
  const expires = new Date()
  expires.setDate(expires.getDate() + EXPIRES_DAYS)

  const sessionId = getSessionId()

  cookieManager.setCookies(ObservableCookies.SessionId, sessionId, {
    ...defaultOptions,
    expires,
  })

  cookieManager.setCookies(ObservableCookies.UserId, data.userID, {
    ...defaultOptions,
    expires,
  })

  cookieManager.setCookies(
    ObservableCookies.AccessToken,
    JSON.stringify({
      accessToken: data.accessToken,
      expireAt: data.accessTokenExpiresAt,
    }),
    {
      ...defaultOptions,
      expires: new Date(data.accessTokenExpiresAt),
    },
  )
  cookieManager.setCookies(ObservableCookies.RefreshToken, data.refreshToken, {
    ...defaultOptions,
    expires,
  })

  if (data.sso_token) {
    cookieManager.setCookies(
      ObservableCookies.TokenId,
      data.sso_token.id_token,
      {
        ...defaultOptions,
        expires,
      },
    )
    cookieManager.setCookies(
      ObservableCookies.SSOToken,
      JSON.stringify({
        refresh_token: data.sso_token.refresh_token,
        access_token: data.sso_token.access_token,
      }),
      {
        ...defaultOptions,
        expires,
      },
    )
  }
}

export const setCodeVerifier = (codeVerifier: string) => {
  cookieManager.setCookies(ObservableCookies.CodeVerifier, codeVerifier, {
    ...defaultOptions,
  })
}

export const removeAuthCookies = () => {
  cookieManager.deleteCookie(ObservableCookies.RefreshToken, defaultOptions)
  cookieManager.deleteCookie(ObservableCookies.AccessToken, defaultOptions)
}

export const removeSessionId = () =>
  cookieManager.deleteCookie(ObservableCookies.SessionId, defaultOptions)

export const getAccessToken = (): TAccessToken =>
  (cookieManager.getCookie(ObservableCookies.AccessToken) as any) || {
    accessToken: '',
    expireAt: '',
  }
export const getRefreshToken = () =>
  cookieManager.getCookie(ObservableCookies.RefreshToken)
export const getSSOToken = (): SSOToken =>
  cookieManager.getCookie(ObservableCookies.SSOToken) as any

export const getCodeVerifier = () =>
  cookieManager.getCookie(ObservableCookies.CodeVerifier)

export const getTokenIdSSO = () =>
  cookieManager.getCookie(ObservableCookies.TokenId)
export const getUserId = () => cookieManager.getCookie(ObservableCookies.UserId)
