/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { FC, ReactNode } from 'react'
import React, { memo, useEffect } from 'react'

import MavisLogo from '~/components/svg/logo.svg?svgr'

export interface Props {
  showLogo?: boolean
  className?: string
  timeout?: number | false
  redirect?: string
  children?: ReactNode
  text?: string
}

const MavisLoading: FC<Props> = ({
  showLogo = true,
  text = 'Loading',
  className = '',
  redirect = '/500',
  timeout = 90000,

  children,
}) => {
  const router = useRouter()

  useEffect(() => {
    if (timeout === false) return

    const timer = setTimeout(() => {
      router.replace(redirect)
    }, timeout)

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer)
  }, [redirect, router, timeout])

  return (
    <div
      className={clsx(
        'fixed inset-0 flex items-center justify-center',
        className,
      )}
    >
      <div className={clsx('-mt-[7vh] flex flex-col items-center')}>
        {children || (
          <>
            {showLogo && <MavisLogo />}
            <p className="three-dots-loading text-text-color-secondary">
              {text}
            </p>
          </>
        )}
      </div>
    </div>
  )
}
export default memo(MavisLoading)
