import { PUBLIC_RONIN_CONFIG } from '@account-service/core/envs'
import * as Sentry from '@sentry/nextjs'
import { useCallback } from 'react'

import { useWrappedToast } from '~/hooks/useWrappedToast'

const isDevelopment = PUBLIC_RONIN_CONFIG === 'development'

export const useSentry = () => {
  const { showError } = useWrappedToast()

  const captureException = useCallback((error?: unknown) => {
    if (isDevelopment) {
      showError(`(Only show dev): ${JSON.stringify(error)}`)
      return
    }
    Sentry.captureException(error)
  }, [])

  return { captureException }
}
