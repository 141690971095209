import { OTP_LENGTH } from 'constants/index'

import { alphabetContainRegex, emailRegex, numberContainRegex } from './regex'

export const validateEmail = (email: string) => {
  if (!email) {
    return 'Email is required'
  }
  if (!emailRegex.test(email)) {
    return 'Invalid email address'
  }
  return ''
}

export const validatePassCode = (value: string) => {
  if (!value) {
    return `Passcode is required.`
  }
  if (value.length !== OTP_LENGTH) {
    return `Passcode must be 6 characters in length.`
  }
  // eslint-disable-next-line prefer-regex-literals
  if (!new RegExp('^\\d+$').test(value)) {
    return 'Passcode must be a valid number'
  }
  return ''
}

export const validateRecoveryCode = (value: string) => {
  if (!value) {
    return `Recovery code is required.`
  }
  if (value.length !== 10) {
    return `Recovery must be 10 characters in length.`
  }
  return ''
}

export const validateDisplayName = (name: string) => {
  if (!name) {
    return 'Display name is not empty'
  }

  // if (/[<.>'"\\]/g.test(name)) {
  //   return `Not allow to use characters: [< > ' \\ "]`
  // }

  return ''
}

export const validatePassword = (password: string) => {
  if (!password) {
    return 'Password is required'
  }
  if (password.length < 8) {
    return 'Password must be at least 8 characters'
  }
  if (!alphabetContainRegex.test(password)) {
    return 'Password must contain at least one alphabet'
  }
  if (!numberContainRegex.test(password)) {
    return 'Password must contain at least one number'
  }
  return ''
}
