import { request } from './request'

type TSocialActionParams = {
  service: 'google' | 'facebook' | 'twitter' | 'discord' | 'apple'
  token: string
  challenge?: string
  wallet_connect?: string
}

type TBlockchainActionParams = {
  service?: 'ronin'
  signature: string
  message?: string
  challenge?: string
  address?: string
  wallet_connect?: string
}

type TConnectAccountParams = TSocialActionParams | TBlockchainActionParams

type TDisconnectAccountParams =
  | Pick<TSocialActionParams, 'service' | 'token'>
  | TBlockchainActionParams

export type TConnectRonin = {
  message: string
  signature: string
  address: string
}

export const getNonceAPI = async (address: string) => {
  return request<{
    nonce: string
    issued_at: string
    not_before: string
    expiration_time: string
  }>({
    url: `v2/public/auth/ronin/fetch-nonce?address=${address}`,
  })
}

export const connectSocialAPI = async (data: TConnectAccountParams) => {
  return request({
    url: 'v2/private/auth/social/link',
    method: 'POST',
    data: {
      ...data,
      is_logout_other_sessions: false,
    },
  })
}

export const disconnectSocialAPI = async (data: TDisconnectAccountParams) => {
  return request({
    url: 'v2/private/auth/social/unlink',
    method: 'POST',
    data: {
      ...data,
      is_logout_other_sessions: false,
    },
  })
}

export const connectRoninAPI = async (data: TConnectRonin) => {
  return request({
    url: 'v2/private/auth/ronin/link',
    method: 'POST',
    data,
  })
}

export const disconnectRoninAPI = async (data: TConnectRonin) => {
  return request({
    url: 'v2/private/auth/ronin/unlink',
    method: 'POST',
    data,
  })
}
