type TResendButtonProps = {
  className?: string
  isResending?: boolean
  counter: number
  onResend: () => void
}
export const ResendButton = (props: TResendButtonProps) => {
  const { className, isResending, counter, onResend } = props
  return (
    <div className={className}>
      {(() => {
        if (isResending)
          return (
            <p className="text-body-s text-text-color-subdued three-dots-loading">
              Resending
            </p>
          )

        if (counter === 0) {
          return (
            <p
              className="text-body-s text-text-color-subdued cursor-pointer select-none hover:opacity-80"
              onClick={onResend}
            >
              Resend
            </p>
          )
        }

        return (
          <p className="text-body-s text-text-color-subdued">
            Not in inbox or spam folders? Resend in ({counter})
          </p>
        )
      })()}
    </div>
  )
}
