export const emailRegexString =
  "^(?:(?:(?:(?:[a-zA-Z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])+(?:\\.([a-zA-Z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])+)*)|(?:(?:\\x22)(?:(?:(?:(?:\\x20|\\x09)*(?:\\x0d\\x0a))?(?:\\x20|\\x09)+)?(?:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e]|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])|(?:(?:[\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}]))))*(?:(?:(?:\\x20|\\x09)*(?:\\x0d\\x0a))?(\\x20|\\x09)+)?(?:\\x22))))@(?:(?:(?:[a-zA-Z]|\\d|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])|(?:(?:[a-zA-Z]|\\d|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])(?:[a-zA-Z]|\\d|-|\\.|~|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])*(?:[a-zA-Z]|\\d|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])))\\.)+(?:(?:[a-zA-Z]|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])|(?:(?:[a-zA-Z]|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])(?:[a-zA-Z]|\\d|-|\\.|~|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])*(?:[a-zA-Z]|[\\x{00A0}\\-\\x{D7FF}\\x{F900}\\-\\x{FDCF}\\x{FDF0}\\-\\x{FFEF}])))\\.?$"
export const emailRegex = new RegExp(emailRegexString)

export const numberContainRegexString = `.*[0-9].*`
export const numberContainRegex = new RegExp(numberContainRegexString)

export const alphabetContainRegexString = `.*[a-zA-Z].*`
export const alphabetContainRegex = new RegExp(alphabetContainRegexString)

export const specialCharContainRegexString = `.*[<>'\\"].*`
export const specialCharContainRegex = new RegExp(specialCharContainRegexString)
