import { Dialog } from '@axieinfinity/matcha'
import type { FC } from 'react'

import {
  NiceModal,
  type NiceModalProps,
  useModal,
} from '~/components/NiceModal'

import { StepInputEmail } from './Steps/StepInputEmail'
import { StepVerifyEmail } from './Steps/StepVerifyEmail'
import { StepsChangeEmail, useChangeEmail } from './useChangeEmail'

export type TChangeEmailModalProps = {
  accessToken?: string
}

export const ChangeEmailModal: FC<TChangeEmailModalProps & NiceModalProps> =
  NiceModal.create(props => {
    const { visible, hide, remove } = useModal()
    const {
      step,
      email,
      isLoading,
      isResending,
      counter,
      handleSendEmail,
      onChangeCode,
      onSubmitEmail,
      onSubmitVerify,
    } = useChangeEmail(props)

    return (
      <Dialog
        className="[&_.dango-dialog-content]:bg-surface-color-default [&_.dango-dialog-content]:border [&_.dango-dialog-content]:border-border-color-default [&_.dango-dialog-body]:p-0 [&_.dango-dialog-close]:hidden [&_.dango-dialog-content]:!p-0 p-16 !w-[390px] sm:!w-[480px]"
        visible={visible}
        onClose={hide}
        afterClose={remove}
        destroyOnClose
        size="small"
        {...props}
      >
        {step === StepsChangeEmail.INPUT && (
          <StepInputEmail onSubmit={onSubmitEmail} onClose={hide} />
        )}
        {step === StepsChangeEmail.VERIFY && (
          <StepVerifyEmail
            email={email}
            isLoading={isLoading}
            isResending={isResending}
            counter={counter}
            handleResendEmail={() => handleSendEmail(email)}
            onChangeCode={onChangeCode}
            onSubmit={onSubmitVerify}
            onClose={hide}
          />
        )}
      </Dialog>
    )
  })
