import { API_ERRORS, useCaptcha } from '@account-service/core'
import type { FormEvent } from 'react'
import { useCallback, useEffect, useState } from 'react'

import {
  type ErrorResponse,
  requestChangeEmailAPI,
  submitChangeEmailAPI,
} from '~/apis'
import { NiceModal } from '~/components/NiceModal'
import { ERRORS, OTP_LENGTH } from '~/constants'
import { ModalKey } from '~/constants/modal'
import { useSentry } from '~/core/sentry'
import { useAuthContext } from '~/hooks/useAuthContext'
import { useCountDown } from '~/hooks/useCountDown'
import { useWrappedToast } from '~/hooks/useWrappedToast'
import { validateEmail } from '~/utils/validator'

import type { TChangeEmailModalProps } from '.'

const MESSAGE_CHANGE_EMAIL_SUCCESS = 'Change email successfully'
const MESSAGE_ERROR_SAME_EMAIL = 'It is the same as your current email-address'

export enum StepsChangeEmail {
  INPUT = 'INPUT',
  VERIFY = 'VERIFY',
}

export const useChangeEmail = (props: TChangeEmailModalProps) => {
  const { accessToken = '' } = props
  const { requestCaptcha } = useCaptcha()
  const { showError, showSuccessMessage } = useWrappedToast()
  const { user, getProfile, getMFAMethods } = useAuthContext()
  const { counter, start, restart } = useCountDown(30)
  const { captureException } = useSentry()

  const [step, setStep] = useState<StepsChangeEmail>(StepsChangeEmail.INPUT)

  const [isLoading, setIsLoading] = useState(false)
  const [isResending, setIsResending] = useState(false)
  const [email, setEmail] = useState('')

  const [otp, setOTP] = useState('')

  const onChangeCode = (otp: string) => {
    setOTP(otp)
  }

  const validateForm = useCallback(
    (form: { email: string }) => {
      const { email } = form

      let errEmail = validateEmail(email)

      if (user?.email === email) {
        errEmail = MESSAGE_ERROR_SAME_EMAIL
      }

      return {
        isValid: !errEmail,
        errors: {
          email: errEmail,
        },
      }
    },
    [user?.email],
  )

  const handleSendEmail = useCallback(
    async (email: string) => {
      setIsResending(true)
      try {
        const [captchaData] = await requestCaptcha()

        if (!captchaData) return

        await requestChangeEmailAPI(accessToken, captchaData, email)

        restart()

        return true
      } catch (error) {
        const { error_status, error_details } = error as ErrorResponse

        if (error_status === 'ERR_VERIFY_EMAIL_TOKEN_ALREADY_SENT') {
          showError(
            `An email has been sent, please check your mailbox. Please try after ${error_details} seconds.`,
          )
          return
        }

        if (API_ERRORS.includes(error_status)) {
          showError(ERRORS[error_status])
          return false
        }

        captureException(error)

        return false
      } finally {
        setIsResending(false)
      }
    },
    [accessToken, captureException, requestCaptcha, restart, showError],
  )

  const onSubmitEmail = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()

      const target = event.target as typeof event.target & {
        email: { value: string }
      }

      const email = target.email.value.trim()

      const { isValid, errors } = validateForm({ email })

      if (!isValid) {
        showError(errors.email)
        return
      }

      const isSent = await handleSendEmail(email)

      if (isSent) {
        setStep(StepsChangeEmail.VERIFY)
      }

      setEmail(email)
    },
    [handleSendEmail, showError, validateForm],
  )

  const onSubmitVerify = useCallback(
    async (event?: React.SyntheticEvent) => {
      event?.preventDefault()

      if (isLoading) return

      setIsLoading(true)
      try {
        await submitChangeEmailAPI(email, otp, accessToken)
        showSuccessMessage(MESSAGE_CHANGE_EMAIL_SUCCESS)

        Promise.all([getProfile(), getMFAMethods()])

        NiceModal.hide(ModalKey.CHANGE_EMAIL)
      } catch (error) {
        const { error_status } = error as ErrorResponse

        if (API_ERRORS.includes(error_status)) {
          showError(ERRORS[error_status])
          return
        }

        captureException(error)
      } finally {
        setIsLoading(false)
      }
    },
    [
      accessToken,
      captureException,
      email,
      getMFAMethods,
      getProfile,
      isLoading,
      otp,
      showError,
      showSuccessMessage,
    ],
  )

  useEffect(() => {
    start()
  }, [start])

  useEffect(() => {
    if (otp.length === OTP_LENGTH) {
      onSubmitVerify()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp])

  return {
    step,
    email,
    counter,
    isLoading,
    isResending,
    handleSendEmail,
    onSubmitEmail,
    onChangeCode,
    onSubmitVerify,
  }
}
